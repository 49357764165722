import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WicAlertModalComponent } from '../../wic-alert-modal/wic-alert-modal.component';
import { WicConfirmModalComponent } from '../../wic-confirm-modal/wic-confirm-modal.component';

export type ModalDismissRole = 'close' | 'dismiss';
export interface ModalDismissResult<T extends ModalDismissRole = ModalDismissRole> {
  role: ModalDismissRole;
}

export type ModalResult<U> =
  | ModalDismissResult<'close'> & {data: U}
  | ModalDismissResult<'dismiss'> & { data?: U };

export const transformModalResult = <T>(modal: NgbModalRef): Promise<ModalResult<T>> =>
  modal.result.then(
    data => ({ role: 'close', data }),
    reason => ({ role: 'dismiss', data: reason })
  );

@Injectable({ providedIn: 'root' })
export class AppUIService {
  constructor(private modals: NgbModal) {}

  showAlert(header: string, message: string): Promise<ModalDismissResult> {
    const modalRef = this.modals.open(WicAlertModalComponent);
    modalRef.componentInstance.modalTitle = header;
    modalRef.componentInstance.message = message;
    return modalRef.result.then(
      () => ({ role: 'close' }),
      () => ({ role: 'dismiss' })
    );
  }

  showConfirm(header: string, message: string): Promise<ModalResult<'yes' | 'no'>> {
    const modalRef = this.modals.open(WicConfirmModalComponent);
    modalRef.componentInstance.modalTitle = header;
    modalRef.componentInstance.message = message;
    return transformModalResult(modalRef);
  }
}
